import Image from "next/image";
import { Col, Container, Row } from "reactstrap";

//local imports
import classes from "@/styles/generic/fulfillment-partner.module.css";
import { fulfillmentPartners } from "@/utils/content";
import { fulFillmentPartners as fulFillmentPartnersImage } from "@/utils/images";
import { routes } from "@/utils/routes";
import { useIsMobile } from "@/hooks/useIsMobile";
import Link from "next/link";

export default function FulfillmentPartners() {
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <Container>
        <h2 className={classes.heading}>{fulfillmentPartners.en.title}</h2>

        {isMobile ? (
          <Row>
            <Col xs={12} lg={6}>
              <Image
                className="d-block m-auto"
                src={fulFillmentPartnersImage}
                alt={fulFillmentPartnersImage}
                height={300}
                width={340}
              />
            </Col>
            <Col className="mt-5 ps-5" xs={12} lg={6}>
              <h3 className={classes.subHeading}>
                {fulfillmentPartners.en.subTitle}
              </h3>
              <p className={classes.para}>{fulfillmentPartners.en.para}</p>
              <Link
                href={routes.fulfillmentPartners}
                className={`btn btn-secondary ${classes.ctaBtn}`}
              >
                {fulfillmentPartners.en.ctaText}
              </Link>
            </Col>
          </Row>
        ) : (
          <Row className="mt-5">
            <Col className="mt-5 ps-5" xs={12} lg={6}>
              <h3 className={classes.subHeading}>
                {fulfillmentPartners.en.subTitle}
              </h3>
              <p className={classes.para}>{fulfillmentPartners.en.para}</p>
              <Link
                href={routes.fulfillmentPartners}
                className={`btn btn-secondary ${classes.ctaBtn}`}
              >
                {fulfillmentPartners.en.ctaText}
              </Link>
            </Col>
            <Col xs={12} lg={6}>
              <Image
                className="d-block m-auto"
                src={fulFillmentPartnersImage}
                alt={fulFillmentPartnersImage}
                height={300}
                width={340}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
