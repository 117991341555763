import { useState } from "react";
import Image from "next/image";
import { FaPlus } from "react-icons/fa";

//local imports
import classes from "@/styles/generic/for-platforms.module.css";
import { Button, Col, Container, Row } from "reactstrap";
import { forPlatforms } from "@/utils/content";
import {
  logo,
  magentoLogo,
  shofifyLogo,
  woocommerceLogo,
} from "@/utils/images";

export default function ForPlatforms() {
  const [selected, setSelected] = useState("shopify");

  const images: any = {
    magento: magentoLogo,
    shopify: shofifyLogo,
    woocommarce: woocommerceLogo,
  };

  return (
    <div className={classes.container}>
      <Container>
        <h2 className={classes.heading}>{forPlatforms.en.title}</h2>
        <Row className="w-75 m-auto mt-5">
          <Col className="pt-4" xs={12} lg={6}>
            <p className={classes.para}>{forPlatforms.en.liveRates}</p>
            <Button
              className={classes.shopify}
              onClick={() => setSelected("shopify")}
              style={{ opacity: selected === "shopify" ? 1 : 0.5 }}
            >
              {forPlatforms.en.shopify}
            </Button>
            <Button
              className={classes.magento}
              onClick={() => setSelected("magento")}
              style={{ opacity: selected === "magento" ? 1 : 0.5 }}
            >
              {forPlatforms.en.magento}
            </Button>
            <Button
              className={classes.woocommarce}
              onClick={() => setSelected("woocommarce")}
              style={{ opacity: selected === "woocommarce" ? 1 : 0.5 }}
            >
              {forPlatforms.en.woocommerce}
            </Button>
          </Col>
          <Col className={`text-center ${classes.imgGrid}`} xs={12} lg={6}>
            <Image
              className="d-block m-auto mb-4"
              src={logo}
              alt={logo}
              height={70}
              width={300}
            />
            <FaPlus className={classes.plus} />
            <Image
              className={`d-block m-auto ${
                selected === "shopify" ? "" : "mt-4"
              }`}
              src={images[selected]}
              alt={images[selected]}
              height={selected === "shopify" ? 150 : 80}
              width={300}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
