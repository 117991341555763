import Image from "next/image";
import { Col, Container, Row } from "reactstrap";
import Link from "next/link";

//local imports
import classes from "@/styles/generic/technology-partner-sec.module.css";
import { technologyPartners } from "@/utils/images";
import { technologyPartners as tp } from "@/utils/content";
import { routes } from "@/utils/routes";

export default function TechnologyPartnerSec() {
  return (
    <div className={classes.container}>
      <Container>
        <h2 className={classes.heading}>{tp.en.title}</h2>
        <Row className="mt-5">
          <Col xs={12} lg={6}>
            <Image
              src={technologyPartners}
              alt={technologyPartners}
              height={350}
              width={600}
            />
          </Col>
          <Col xs={12} lg={6}>
            <div className="w-75 ms-5 mt-5 pt-2">
              <h3 className={classes.subHeading}>{tp.en.subHeading}</h3>
              <p className={classes.para}>{tp.en.para}</p>
              <Link
                href={routes.technologyPartner}
                className={`btn btn-secondary ${classes.ctaBtn}`}
              >
                {tp.en.ctaText}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
