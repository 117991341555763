import Head from "next/head";
import { GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

//local imports
import seoData from "@/data/seo/page-data.json";
import About from "@/components/generic/about";
import ForPlatforms from "@/components/generic/for-platforms";
import FulfillmentPartners from "@/components/generic/fulfillment-partner";
import Help from "@/components/generic/help";
import HowItWorks from "@/components/generic/how-it-works";
import ReferralProgram from "@/components/generic/referral-program";
import TechnologyPartnerSec from "@/components/generic/technology-partner-sec";
import classes from "@/styles/index.module.css";
import SeoMetaData from "@/components/utils/seo-metadata";
import { getIsSsrMobile } from "@/utils/user-agent-detector";
import { routes } from "@/utils/routes";

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    if (router.query["affiliateLink"]) {
      router.push(
        `${routes.register}?affiliateLink=${router.query["affiliateLink"]}`
      );
    }
    console.log(router.query["redirect"]);

    if (router.query["redirect"]) {
      switch (router.query["redirect"]) {
        case "register":
          router.push(routes.register);
          break;
      }
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>{seoData.LANDING_PAGE.title}</title>
        <SeoMetaData data={seoData.LANDING_PAGE} />
      </Head>
      <div className={classes.container}>
        <About />
        <Help />
        <HowItWorks />
        <ForPlatforms />
        <TechnologyPartnerSec />
        <FulfillmentPartners />
        <ReferralProgram />
      </div>
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      isSsrMobile: getIsSsrMobile(context),
    },
  };
}
