import { Card, CardBody, Col, Container, Row } from "reactstrap";

//local imports
import classes from "@/styles/generic/referral-program.module.css";
import { referralProgram } from "@/utils/content";
import Image from "next/image";
import {
  refCardImg1,
  refCardImg2,
  refCardImg3,
  stripeLandingPageImg,
} from "@/utils/images";
import { routes } from "@/utils/routes";
import Link from "next/link";

export default function ReferralProgram() {
  return (
    <div className={classes.container}>
      <Container>
        <h2 className={classes.heading}>{referralProgram.en.title}</h2>
        <h3 className={classes.subHeading}>{referralProgram.en.subTitle}</h3>
        <Row className={`m-auto mt-4 w-65 ${classes.row}`}>
          <Col xs={12} lg={4}>
            <Card className={classes.card}>
              <CardBody>
                <Image
                  className="d-flex m-auto my-4"
                  src={refCardImg1}
                  alt={refCardImg1}
                  height={50}
                  width={55}
                />
                <h4 className={classes.cardHeading}>
                  {referralProgram.en.card1Heading}
                </h4>
                <p className={classes.cardPara}>
                  {referralProgram.en.card1Para}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card className={classes.card}>
              <CardBody>
                <Image
                  className="d-flex m-auto my-4"
                  src={refCardImg2}
                  alt={refCardImg2}
                  height={50}
                  width={55}
                />
                <h4 className={classes.cardHeading}>
                  {referralProgram.en.card2Heading}
                </h4>
                <p className={classes.cardPara}>
                  {referralProgram.en.card2Para}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card className={classes.card}>
              <CardBody>
                <Image
                  className="d-flex m-auto my-4"
                  src={refCardImg3}
                  alt={refCardImg3}
                  height={45}
                  width={55}
                />
                <h4 className={classes.cardHeading}>
                  {referralProgram.en.card3Heading}
                </h4>
                <p className={classes.cardPara}>
                  {referralProgram.en.card3Para}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="text-center">
          <Link className={classes.ctaBtn} href={routes.register}>
            {referralProgram.en.ctaText}
          </Link>
        </div>
        <div className="text-end">
          <Image
            src={stripeLandingPageImg}
            alt={stripeLandingPageImg}
            height={125}
            width={300}
          />
        </div>
      </Container>
    </div>
  );
}
